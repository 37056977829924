import React, {Component} from 'react'
import ReturnPage from '../../shared/ReturnPage'
import OrderMeta from './OrderMeta'
import OrderItems from './OrderItems'
import {getOrder, getOrderRefund, getInvoiceCharge, getOrderFulfilledItems} from '../../../services/api/OrdersServices'
import OrderBuyerDetails from './buyer-detail/OrderBuyerDetails'
import OrderNote from './OrderNote'
import OrderTags from './OrderTags'
import OrderMappingConditions from './OrderMappingConditions'
import PropTypes from 'prop-types'
import OrderInvoice from './OrderInvoice/OrderInvoice'
import OrderFulfilled from './fulfilled/OrderFulfilled'
import OrderRejectedItems from './rejected/OrderRejectedItems'
import broker from '../OrderBroker'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import OrderJobContainer from './jobs/OrderJobContainer'
import OrderHistoriesContainer from './histories/OrderHistoriesContainer'
import OrderPreference from './preference/OrderPreference'
import OrderShippingPlan from './OrderShippingPlan'
import OrderStoreMode from "./preference/OrderStoreMode"
import OrderZone from "./OrderZone"
import BrandingContainer from "./branding/BrandingContainer"
import SellerInvoice from "./OrderInvoice/SellerInvoice"
import RequestList from "./RequestList"
import {getLanguageStore} from "../../../services/api/PlatformIntergationServices"
import OrderInvoicePreview from "./OrderInvoice/OrderInvoicePreview"
import ReturnAddressContainer from './ReturnAddressContainer'
import {ListArtworkType} from '../../../statics/artworkType'
import {CloseOutlined, ExclamationCircleOutlined, FileTextOutlined, EditOutlined} from '@ant-design/icons'
import {Alert} from 'reactstrap'
import { Tooltip } from 'antd'
import { getLocalData } from '../../../services/StorageServices'
import { getListSuppliersV2 } from '../../../services/api/SuppliersServices'
import ModalEditLabel from './ModalEditLabel'

class OrderPage extends Component {
    state = {
        order: null,
        error: '',
        refund: 0,
        language: 'vi',
        languageError: '',
        fulfilledPackages: [],
        isMapped: false,
        currency: 'USD',
        isTotalBaseCostFromStore: false,
        baseCost: 120,
        warningVisible: true,
        artworkLevel: '',
        artworkPack: 0,
        artWorks: [],
        fetchOrderPackageCount: 0,
        listSuppliers: [],
        orderItems: [],
        openEditLabel: false,
    }

    _subscription1 = null
    _subscription2 = null
    _subscription3 = null
    _subscription4 = null
    _subscription5 = null
    _subscription6 = null
    _subscription7 = null
    _subscription8 = null
    _subscription9 = null

    componentDidMount = async () => {
        await this._fetchSuppliers()
        await this._fetchOrderDetail()
        await this._fetchOrderRefund()
        await this._fetchInvoiceCharge()
        await this._fetchOrderFulfilledItems()
        this._subscription1 = broker.subscribe('ORDER_REJECTED', this._handleUpdateOrderDetail)
        this._subscription2 = broker.subscribe('ORDER_ITEM_REJECTED', this._handleUpdateOrderDetail)
        this._subscription3 = broker.subscribe('ORDER_RESTORE', this._handleUpdateOrderDetail)
        this._subscription4 = broker.subscribe('UNDO_REJECTED_ORDER_ITEM', this._handleUpdateOrderDetail)
        this._subscription5 = broker.subscribe('ORDER_REFRESH_ORDER', this._handleUpdateOrderDetail)
        this._subscription6 = broker.subscribe('ORDER_REFRESH_REFUNDED', this._fetchOrderRefund)
        this._subscription7 = broker.subscribe('ORDER_REFRESH_REFUNDED', this._fetchInvoiceCharge)
        this._subscription8 = broker.subscribe("TRACKING_UPDATED", this._handleTrackingUpdated)
        this._subscription9 = broker.subscribe("ORDER_RESTORE", this._handleTrackingUpdated)
        await this._fetchLanguageStore()
    }

    componentWillUnmount() {
        broker.unsubscribe(this._subscription1)
        broker.unsubscribe(this._subscription2)
        broker.unsubscribe(this._subscription3)
        broker.unsubscribe(this._subscription4)
        broker.unsubscribe(this._subscription5)
        broker.unsubscribe(this._subscription6)
        broker.unsubscribe(this._subscription7)
        broker.unsubscribe(this._subscription8)
        broker.unsubscribe(this._subscription9)
    }

    _fetchOrderDetail = async () => {
        const {orderId} = this.props

        try {
            const {data, success, message} = await getOrder(orderId)
            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                order: data,
                error: '',
            }, () => {
                broker.publish('ORDER_REFRESHED_ORDER')
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _handleUpdateTotalPushToProduction = (total) => {
        this.setState(({order}) => {
            return {
                order: {
                    ...order,
                    count_push_to_production: total,
                },
            }
        })
    }

    _handleUpdateOrderDetail = () => {
        console.log('Refreshing order...')
        this._fetchOrderDetail()
    }

    _handleRefreshAddress = newAddress => {
        const {order} = this.state

        this.setState({
            order: {
                ...order,
                address: {
                    ...order.address,
                    ...newAddress,
                },
            },
        })
    }

    _fetchLanguageStore = async () => {
        this.setState({
            languageError: ''
        })

        try {
            const {order} = this.state
            const response = await getLanguageStore(order.namespace)

            const {success, data, message: error} = response

            if (!success) {
                return this.setState({
                    languageError: error
                })
            }

            this.setState({
                language: (data.hasOwnProperty('preferred_language') ? data.preferred_language : 'vi')
            })
        } catch (e) {
            this.setState({
                languageError: e.message
            })
        }
    }

    _fetchOrderRefund = async () => {
        try {
            const {orderId} = this.props
            const {data, success, message} = await getOrderRefund(orderId)

            if (!success) {
                return this.setState({
                    error: message
                })
            }

            this.setState({
                refund: data,
                error: ''
            })
        } catch (e) {
            return this.setState({
                error: e.message
            })
        }
    }

    _fetchInvoiceCharge = async () => {
        try {
            const {orderId} = this.props
            const {data, success, message} = await getInvoiceCharge(orderId)

            if (!success) {
                return this.setState({
                    error: message
                })
            }

            this.setState({
                charge: data,
                error: ''
            })
        } catch (e) {
            return this.setState({
                error: e.message
            })
        }
    }

    _fetchOrderFulfilledItems = async () => {
        try {
            const {orderId} = this.props
            const {data, success, message} = await getOrderFulfilledItems(orderId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                fulfilledPackages: data,
                error: "",
                fetchOrderPackageCount: (this.state.fetchOrderPackageCount || 0) + 1
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _fetchSuppliers = async () => {
        try {
            const { data, success, message } = await getListSuppliersV2({active:true})
            if (!success) throw new Error(message)
            const { suppliers } = data
            this.setState({
                listSuppliers : suppliers
            })
            
        } catch (error) {
            console.log(error.message)
        }
    }

    _handleTrackingUpdated = () => {
        this._fetchOrderFulfilledItems()
    }

    _getStatusMapping = (status) => {
        this.setState({
            isMapped: status
        })
    }

    _getCurrencyPrice = (currency) => {
        this.setState({
            currency: currency
        })
    }

    _onTotalBaseCostFromStore = (value) => {
        this.setState({
            isTotalBaseCostFromStore: value
        })
    }

    _getTotalBaseCost = (price) => {
        this.setState({
            baseCost: price
        })
    }

    fetchHistory = () => {
        this.setState({
            fetchHistoryCounter: this.state.fetchHistoryCounter ? this.state.fetchHistoryCounter + 1 : 1
        })
    }

    _toggleWarning = () => {
        this.setState({warningVisible: false})
    }

    _handleChangeArtwork = (value) => {
        const {artWorks} = this.state
        artWorks.push(value)
        const newlist = [...new Set(artWorks)]
        this.setState({artWorks: newlist})
    }

    _handleSetOrderItems = (items) => {
        this.setState({orderItems: items})
    }

    _handleEditLabel = (open) => {
        this.setState({
            openEditLabel: open
        })
    }

    render() {
        const {
            order, error, refund, charge, language, languageError,
            fulfilledPackages, currency, baseCost, isMapped, fetchHistoryCounter,
            isTotalBaseCostFromStore, warningVisible, artworkLevel, artworkPack,
            artWorks, listSuppliers, orderItems, openEditLabel,
        } = this.state

        if (!order) {
            return <div className="OrderPage">
                <div className="text-danger mt-3">{error}</div>
            </div>
        }

        const {
            id,
            updated,
            fulfillment_status,
            note,
            tags,
            namespace,
            payment_status,
            is_legacy,
            completed_at,
            can_restore,
            custom_shipping_cost,
            order_type,
            shipping_plan,
        } = order
        const {preference, requests, manifest_info} = order
        const { warehouse_info = {}, label = '', tracking_number = '' } = { ...preference }
        const {manifest_id = '', manifest_label= '', manifest_quantity = 0 } = Object.assign({}, manifest_info)
        const {orderId, fromOrdersPage} = this.props
        const returnUrl = fromOrdersPage === 1 ? '/a/orders' : `/a/orders?page=${fromOrdersPage}`
        const showWarning = artWorks.length > 0 && fulfillment_status !== 'fulfilled'
        const artworkType = ListArtworkType.filter(i => artWorks.includes(i.value)).map(i => i.label)
        const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
        const {slug = ''} = Object.assign({}, shipping_plan)

        return (
            <div className="OrderPage mx-3 mx-lg-0">
                <DocTitleByStore title={`#${id} - Orders`}/>

                <ReturnPage url={returnUrl} title="Orders"/>

                <OrderMeta
                    order={order}
                    code={id}
                    time={updated}
                    fulfillmentStatus={fulfillment_status}
                    namespace={namespace}
                    paymentStatus={payment_status}
                    isLegacy={is_legacy}
                    completedAt={completed_at}
                    canRestore={can_restore}
                    orderId={orderId}
                    onUpdateTotalPushToProduction={this._handleUpdateTotalPushToProduction}
                    onFetchOrder={this._fetchOrderDetail}
                    fulfilledPackages={fulfilledPackages}
                />

                <div className="row mt-4">
                    <div className="col-lg-9">
                        <RequestList requests={requests}/>

                        <div className="Row d-flex flex-wrap align-items-center mb-3 justify-content-end">
                            <div className='alertWarning my-2 mr-2'>
                                {showWarning &&
                                <Alert
                                    color="warning"
                                    isOpen={warningVisible}
                                    className='mb-0 d-flex align-items-center'
                                >
                                    <ExclamationCircleOutlined className='mr-2' style={{fontSize: '20px'}}/>
                                    {artworkType.length === 1 &&
                                    <span>Warning! There are {artworkType[0].toLowerCase()}, please map products with variant pack 1.
                                            </span>
                                    }
                                    {artworkType.length > 1 &&
                                    <span>Warning! There are {artworkType.join(' and ').toLowerCase()}, please map products with variant pack 1.
                                            </span>
                                    }
                                    <button className='ml-3 p-0 close' onClick={this._toggleWarning}>
                                        <CloseOutlined style={{fontSize: '17px'}}/>
                                    </button>
                                </Alert>
                                }
                            </div>
                            <div className="mx-3 mr-sm-0">
                                <div className="d-sm-flex align-items-center justify-content-end">
                                    <OrderStoreMode order={order}/>
                                    <OrderZone order={order}/>
                                    <OrderShippingPlan order={order}/>
                                </div>
    
                                <div className="d-sm-flex align-items-center justify-content-end mt-3">
                                    {!!manifest_label && !!manifest_id &&
                                        <div className="ManifestID mb-sm-0 mb-1 mr-2">
                                            <span className="Label">ManifestID: </span>
                                            <Tooltip placement='top' title={manifest_label} overlayClassName='tooltipLabel'>
                                                <a href={manifest_label} target='_blank' rel="noopener noreferrer" style={{ color: 'red', fontWeight: 'bold' }}>{manifest_id}</a>
                                            </Tooltip>
                                        </div>
                                    }

                                    {!!manifest_quantity && 
                                        <div className="ManifestID mb-sm-0 mb-1 mr-2">
                                            <span className="Label">Quantity: </span>
                                            <strong style={{ color: 'red' }}>{manifest_quantity}</strong>
                                        </div>
                                    }

                                    {!!warehouse_info.merchize_warehouse_name &&
                                        <div className="Warehouse mb-sm-0 mb-1 mr-2">
                                            <span className="Label">Warehouse: </span>
                                            <strong style={{ color: 'red' }}>
                                                {warehouse_info.merchize_warehouse_name}
                                                {warehouse_info.merchize_warehouse_name ==='TX' && '-FSUS'}
                                                {warehouse_info.merchize_warehouse_name ==='TX1' && '-MCUS'}
                                                {warehouse_info.merchize_warehouse_name ==='NJ' && '-PYUS'}
                                                {warehouse_info.merchize_warehouse_name ==='CA' && '-SPUS'}
                                            </strong>
                                        </div>
                                    }

                                    {
                                        !!label && 
                                        <React.Fragment>
                                            <Tooltip
                                                title={
                                                    <div className='d-flex align-items-center'>
                                                        {label}
                                                        <EditOutlined 
                                                            style={{ marginLeft: 8, cursor: 'pointer' }} 
                                                            onClick={() => this._handleEditLabel(true)} 
                                                        />
                                                    </div>
                                                }
                                                placement='topRight'
                                                overlayClassName='tooltipLabel'
                                            >
                                                <a href={label} target='_blank' rel="noopener noreferrer">
                                                    <FileTextOutlined style={{ cursor: 'pointer' }} />
                                                </a>
                                            </Tooltip>
                                            <ModalEditLabel
                                                open={openEditLabel}
                                                onClose={() => this._handleEditLabel(false)}
                                                label={label}
                                                trackingNumber={tracking_number}
                                                orderId={order._id}
                                                reloadOrder={this._fetchOrderDetail}
                                            />
                                        </React.Fragment>
                                    }
                                    
                                </div>
                            </div>
                        </div>

                        <OrderItems
                            order={order}
                            namespace={namespace}
                            tags={tags}
                            packageName={id}
                            storeMode={(!!preference && !!preference.storeMode && preference.storeMode) || 'normal'}
                            orderId={orderId}
                            orderType={order_type}
                            customShippingCost={custom_shipping_cost}
                            fulfillmentStatus={fulfillment_status}
                            language={language}
                            languageError={languageError}
                            onGetStatusMapping={this._getStatusMapping}
                            onGetTotalBaseCost={this._getTotalBaseCost}
                            onGetCurrencyPrice={this._getCurrencyPrice}
                            onTotalBaseCostFromStore={this._onTotalBaseCostFromStore}
                            setArtworks={(value) => this._handleChangeArtwork(value)}
                            listSuppliers={listSuppliers}
                            onSetOrderItems={this._handleSetOrderItems}
                        />
                        <OrderFulfilled
                            order={order}
                            orderId={orderId}
                            completedAt={completed_at}
                            code={id}
                            preference={preference}
                            fulfillmentStatus={fulfillment_status}
                            paymentStatus={payment_status}
                            canRestore={can_restore}
                            refunded={refund.totalRefund || 0}
                            invoiceRefund={refund}
                            onUpdateTotalPushToProduction={this._handleUpdateTotalPushToProduction}
                            fulfilledPackages={fulfilledPackages}
                            error={error}
                            orderType={order_type}
                            fetchHistory={this.fetchHistory}
                            fetchOrderFulfilledItems={this._fetchOrderFulfilledItems}
                            setArtworks={(value) => this._handleChangeArtwork(value)}
                            fetchOrderPackageCount={this.state.fetchOrderPackageCount}

                        />
                        <OrderRejectedItems
                            orderId={orderId}
                            fulfillmentStatus={fulfillment_status}
                            code={id}
                            refunded={refund.totalRefund || 0}
                            invoiceRefund={refund}
                            paymentStatus={payment_status}
                        />

                        <BrandingContainer orderId={orderId}/>

                        <div className="row mt-3">
                            <div className="col-6">
                                {
                                    (order_type === 'FBA' || slug === "express") ? <OrderInvoicePreview
                                        isMapped={isMapped}
                                        customShippingCost={custom_shipping_cost || 0}
                                        baseCost={baseCost}
                                        currency={currency}
                                        orderId={orderId}
                                        fulfillmentStatus={fulfillment_status}
                                        isTotalBaseCostFromStore={isTotalBaseCostFromStore}
                                        listSuppliers={listSuppliers}
                                        orderItems={orderItems}
                                        orderType={order_type}
                                        slug={slug}
                                    /> : ''
                                }

                                <OrderInvoice
                                    orderId={orderId}
                                    fulfillmentStatus={fulfillment_status}
                                    refunded={refund.totalRefund || 0}
                                    charge={charge}
                                    order={this.state.order}
                                />
                            </div>
                            <div className="col-6">
                                <SellerInvoice
                                    orderId={orderId}
                                    fulfillmentStatus={fulfillment_status}
                                    refunded={refund.totalRefund || 0}
                                    charge={charge}
                                    order={this.state.order}
                                />
                            </div>
                        </div>

                        <div className="mt-3">
                            <OrderHistoriesContainer orderId={orderId} fetchHistoryCounter={fetchHistoryCounter}/>
                        </div>

                        <div className="mt-3">
                            <OrderJobContainer orderId={order._id} permissionsData={permissionsData}/>
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <OrderBuyerDetails
                            order={order}
                            tags={tags}
                            language={language}
                            languageError={languageError}
                            onChangeAddress={this._handleRefreshAddress}
                        />

                        <div className='mt-3'>
                            <ReturnAddressContainer order={order}/>
                        </div>

                        <div className="mt-3">
                            <OrderMappingConditions namespace={namespace}/>
                        </div>
                        <div className="mt-3">
                            <OrderTags orderId={orderId} tags={tags || []}/>
                        </div>

                        <div className="mt-3">
                            <OrderNote orderId={orderId} note={note || ''}/>
                        </div>

                        <div className="mt-3">
                            <OrderPreference order={order}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

OrderPage.propTypes = {
    orderId: PropTypes.string.isRequired,
    fromOrdersPage: PropTypes.number.isRequired,
}

export default OrderPage
