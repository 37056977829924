import React, {Component} from 'react'
import OrderItemProduct from "./OrderItemData/OrderItemProduct"
import OrderItemMap from "./OrderItemData/OrderItemMap"
import OrderItemDesignV3 from "./OrderItemData/OrderItemDesignV3"
import PropTypes from 'prop-types'
import { getArtworksByOrderItemV3, getValidateArtwork } from '../../../../services/api/ArtworksServices'
import {getListCategories} from "../../../../services/api/ProductsServices"
import { ConsoleSqlOutlined } from '@ant-design/icons'

class OrderItemRow extends Component {
    state = {
        artworkType: 'productvariant',
        artworks: [],
        artworksMore: [],
        error: "",
        piecesPerPack: 0,
        alertItem: '',
        validates:[],
        categories: []
    }

    _handleChangeArtworkType = (type) => {
        this.setState({
            artworkType: type
        })
    }

    _handleAlert = (value) => {
        this.setState({
            alertItem: value
        })
    }

    _fetchArtworks = async () => {
        const {orderItem, orderId, setArtworks} = this.props
        const {_id: orderItemId, productLineVariant}= orderItem

        this.setState({validates: []})

        try {
            const {data, success, message} = await getArtworksByOrderItemV3({orderId, orderItemId})

            if (!success) {
                return this.setState({
                    error: message,
                })
            }
            if (data.length > 0 && !!data[0].artworks && data[0].artworks.length > 0) {
                const {level, pack} = data[0].artworks[0]
                if((level==='order'|| level==='productvariant') && pack > 1) {
                    setArtworks(level)
                }
            }
            this.setState({
                artworks: data.length >= 2 ? data.slice(0, 1) : data,
                artworksMore: data.length >= 2 ? data.slice(1, data.length) : []
            }, () => {
                const onChangeArtworkType = this._handleChangeArtworkType

                if (typeof onChangeArtworkType === 'function') {
                    if (data.length > 0 && !!data[0].artworks && data[0].artworks.length > 0) {
                        onChangeArtworkType(data[0].artworks[0].level)
                    }
                }

                this.setState({
                    piecesPerPack: data.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0)
                })

                data.map(async (item) => {
                    const listIdArtwork = item.artworks.map((item) => {
                        return item._id
                    })
                    const productMapId = (productLineVariant && productLineVariant.product) ? productLineVariant.product._id : ''

                    if (!!productMapId && !!listIdArtwork && listIdArtwork.length > 0) {
                        await this._fetchValidateDimension(productMapId, listIdArtwork, item.side_group || null)
                    }
                })
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.allArtworks !== prevProps.allArtworks) {
            const {orderItem, setArtworks, allArtworks} = this.props
            const {_id: orderItemId}= orderItem

            this.setState({validates: []})

            if(allArtworks[orderItemId]){
                const data = allArtworks[orderItemId]
                if (data.length > 0 && !!data[0].artworks && data[0].artworks.length > 0) {
                    const {level, pack} = data[0].artworks[0]
                    if((level==='order'|| level==='productvariant') && pack > 1) {
                        setArtworks(level)
                    }
                }
                this.setState({
                    artworks: data.length >= 2 ? data.slice(0, 1) : data,
                    artworksMore: data.length >= 2 ? data.slice(1, data.length) : []
                }, () => {
                    const onChangeArtworkType = this._handleChangeArtworkType
    
                    if (typeof onChangeArtworkType === 'function') {
                        if (data.length > 0 && !!data[0].artworks && data[0].artworks.length > 0) {
                            onChangeArtworkType(data[0].artworks[0].level)
                        }
                    }
    
                    this.setState({
                        piecesPerPack: data.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0)
                    })
                })
            }
        }

        if (this.props.validateDimension !== prevProps.validateDimension) {
            const {validateDimension, orderItem} = this.props
            const {_id: orderItemId}= orderItem
            const validateItem = validateDimension.filter(validate => validate.id === orderItemId)
            
            if(validateItem.length > 0){
                
                const validateAll = validateItem.map(val => {
                    const {message, correctDimensions, validDimension, side_group} = val

                    return {
                        side_group: side_group || null,
                        errorValidate: message,
                        correctDimensions: correctDimensions,
                        validDimension: validDimension
                    }
                })

                this.setState({
                    validates: [
                        ...this.state.validates,
                        ...validateAll
                    ]
                })
            }else{
                this.setState({
                    validates: [
                        ...this.state.validates,
                        {
                            side_group: null,
                        }
                    ]
                })
            }
        }
    }

    _fetchListCategories = async () => {
        try {
            const {data, success, message} = await getListCategories({limit: -1})

            if (!success) {
                return this.setState({error: message})
            }

            const {categories} = data

            return this.setState({
                categories: categories,
                error: ""
            })
        } catch (e) {
            return this.setState({
                error: e.message
            })
        }
    }

    _fetchValidateDimension = async (productId, artworkIds, side_group) => {
        try {
            const response = await getValidateArtwork({productId: productId, artworkIds: artworkIds})

            const {success, data, message: error} = response

            if (!success) {
                return this.setState({
                    validates: [...this.state.validates, {side_group: side_group, errorValidate: error}]
                })
            }

            const {validDimension, correctDimensions, message} = data

            this.setState({
                validates: [
                    ...this.state.validates,
                    {
                        side_group: side_group,
                        errorValidate: message,
                        correctDimensions: correctDimensions,
                        validDimension: validDimension
                    }
                ]
            })

        } catch (e) {
            this.setState({
                error: e.message
            })
        }
    }

    componentDidMount() {
        // this._fetchArtworks()
        this._fetchListCategories()
    }

    render() {
        const {
            artworkType,
            artworks,
            artworksMore,
            error,
            piecesPerPack,
            alertItem,
            validates,
            categories
        } = this.state
        const {
            orderItem,
            _fetchOrderItems,
            orderId,
            storeMode,
            fulfillmentStatus,
            packageName,
            namespace,
            language,
            languageError,
            orderType,
            order,
            listSuppliers,
        } = this.props
        const {_id: orderItemId, productLineVariant, product, request_update_status} = orderItem

        return (
            <tr className="OrderItemRow" style={!!alertItem? {border:'3px solid red'}: {}}>
                <OrderItemProduct
                    orderItem={orderItem}
                    orderItemId={orderItemId}
                    _fetchOrderItems={_fetchOrderItems}
                    orderId={orderId}
                    fulfillmentStatus={fulfillmentStatus}
                />

                <OrderItemMap
                    order={order}
                    orderId={orderId}
                    storeMode={storeMode}
                    orderItem={orderItem}
                    orderItemId={orderItemId}
                    productLineVariant={productLineVariant || {}}
                    artworkType={artworkType}
                    fulfillmentStatus={fulfillmentStatus}
                    orderType={orderType}
                    _fetchOrderItems={_fetchOrderItems}
                    artworks={artworks}
                    artworksMore={artworksMore}
                    categories={categories}
                    alertItem={alertItem}
                    error={error}
                    _handleAlert={this._handleAlert}
                    namespace={namespace}
                    listSuppliers={listSuppliers}
                />

                <OrderItemDesignV3
                    language={language}
                    languageError={languageError}
                    namespace={namespace}
                    tags={request_update_status}
                    orderId={orderId}
                    product={product}
                    orderItemId={orderItemId}
                    productMapId={(productLineVariant && productLineVariant.product) ? productLineVariant.product._id : ''}
                    packageName={packageName}
                    fulfillmentStatus={fulfillmentStatus}
                    onChangeArtworkType={this._handleChangeArtworkType}
                    artworks={artworks||[]}
                    artworksMore={artworksMore}
                    error={error}
                    _fetchArtworks={this._fetchArtworks}
                    piecesPerPack={piecesPerPack}
                    validates={validates}
                />
            </tr>
        )
    }
}

OrderItemRow.propTypes = {
    language: PropTypes.string,
    languageError: PropTypes.string,
    namespace: PropTypes.string,
    orderItem: PropTypes.object.isRequired,
    storeMode: PropTypes.string.isRequired,
    packageName: PropTypes.string
}

export default OrderItemRow
