import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SupplierInfoModal from '../../../../order-partners-v2/components/data/SupplierInfoModal'
import ProductsSupplierItem from './ProductsSupplierItem'

class ProductsSupplier extends Component {
    state = {
        visibleSupplier: false,
    }

    _handleChangeSupplier = (value) => {
        const {onChangeSupplier} = this.props
        if (typeof onChangeSupplier === 'function') {
            onChangeSupplier(value)
        }
    }

    _handleSetVisibleSupplier = (value) => {
        this.setState({
            visibleSupplier: value
        })
    }

    _renderSupplier = (supplier) => {
        if(supplier !== null){
            return supplier.map(item => {
                return <li className="cursor-pointer" key={item._id} value={item._id}
                onMouseOver={() => this._handleSetVisibleSupplier(true)}
                onMouseLeave={() => this._handleSetVisibleSupplier(false)}
                >
                    <span onClick={() => this._handleChangeSupplier([item._id])}>
                    {item.name}
                    </span>

                    {
                        this.state.visibleSupplier && <SupplierInfoModal visibleSupplier={this.state.visibleSupplier} supplier={item.name}/>
                    }
                </li>
            })
        }
    }

    _renderSupplierV2 = (supplier) => {
        if(supplier !== null){
            return supplier.map(item => {
                return <ProductsSupplierItem key={item._id} supplier={item.name} supplierId={item._id} onChangeSupplier={this.props.onChangeSupplier}/>
            })
        }
    }

    render() {
        const {suppliers} = this.props 
        
        const renderSupplier = suppliers !== undefined && this._renderSupplierV2(suppliers)

        return (
            <td className="ProductsSupplier border-0">
                <ul className='listSupplier'>
                    {suppliers !== null ? renderSupplier : '' }
                </ul>
            </td>
        )
    }
}

ProductsSupplier.propTypes = {
    onChangeSupplier: PropTypes.func.isRequired,
}

export default ProductsSupplier