import React from 'react'
import {Select} from "antd"

const {Option} = Select

const SelectSearch = (props) => {
    const {listSelect, name, value, label, loading, showSearch} = props

    const _handleChangeSelect = (value) => {
        props.onChange(name, value)
    }

    return (
        <div className="InputSearch">
            <div className="font-weight-500 LabelFilter mb-1">{label}</div>

            <Select
                style={{width: '100%'}}
                defaultValue=""
                value={value}
                disabled={loading}
                onChange={_handleChangeSelect}
                showSearch={showSearch ? true : false}
            >
                {
                    listSelect.map(item => {
                        return <Option value={item.value} key={item.value}>{item.name}</Option>
                    })
                }
            </Select>
        </div>
    )
}

export default SelectSearch
