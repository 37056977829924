import React, {Component} from 'react'
import OrderItemHead from "./OrderItemTable/OrderItemHead"
import OrderItemRow from "./OrderItemTable/OrderItemRow"
import PropTypes from 'prop-types'
import { getArtworksByOrderItemsV4, validateDimensionMultiple } from '../../../services/api/ArtworksServices'

class OrderItemTable extends Component {
    state = {
        allArtworks: [],
        validateDimension: []
    }

    fetchArtworksOrders  = async() => {
        const {orderId, orderItems} = this.props
        const orderItemIds = orderItems.map(item => item._id)
        const artworks = {}
        try{
            const payload = {
                orderItemIds
            }
            const {data, success, message} = await getArtworksByOrderItemsV4(orderId, payload)
            
            if (success) {
                data.length > 0 && orderItemIds.map(id => {
                    const dataItem = data.filter(item => item.order_item === id)
                    if(dataItem.length > 0) {
                        const {order_item, ...rest} = dataItem[0]
                        artworks[id] = rest.data
                    }else{
                        artworks[id] = undefined
                    }
                })
            }
        } catch (e){

        }

        this.setState({
            allArtworks: artworks
        }, async () => {
            const payloadItems = []
            orderItems.map(item => {
                const {_id: orderItemId, productLineVariant}= item
                const dataArtworks = artworks[orderItemId] && artworks[orderItemId].length > 0 ? artworks[orderItemId] : []

                dataArtworks.length > 0 && dataArtworks.map(artworkItem => {
                    const listIdArtwork = artworkItem.artworks.map((item) => {
                        return item._id
                    })
                    const productMapId = (productLineVariant && productLineVariant.product) ? productLineVariant.product._id : ''

                    if (!!productMapId && !!listIdArtwork && listIdArtwork.length > 0) {
                        payloadItems.push({
                            id: orderItemId,
                            side_group: artworkItem.side_group || null,
                            productId: productMapId,
                            artworkIds: listIdArtwork
                        })
                    }
                })
            })

            if (payloadItems.length > 0){
                await this.fetchValidateDimension(payloadItems)
            }
        })
    }

    fetchValidateDimension = async (payloadItems) => {
        try {
            const payload = {
                items: payloadItems
            }
            const {data, success, message} = await validateDimensionMultiple(payload)
            
            if (success) {
                this.setState({
                    validateDimension: data
                })
            }
        } catch (e) {

        }
    }

    componentDidMount() {
        this.fetchArtworksOrders()
        // this.fetchValidateDimension()
    }

    render() {
        const {language, languageError, order} = this.props
        const {
            orderItems = {},
            _fetchOrderItems,
            orderId,
            storeMode,
            fulfillmentStatus,
            packageName,
            tags,
            namespace,
            orderType,
            setArtWorkLevel,
            setArtWorkPack,
            setArtworks,
            listSuppliers,
        } = this.props
        const {allArtworks, validateDimension} = this.state

        return (
            <div className="OrderItemTable">
                <table className="table table-bordered">
                    <OrderItemHead/>
                    <tbody>
                    {
                        orderItems.map(item => {
                            return <OrderItemRow
                                order={order}
                                language={language}
                                languageError={languageError}
                                tags={tags}
                                key={item._id}
                                fulfillmentStatus={fulfillmentStatus}
                                storeMode={storeMode}
                                orderId={orderId}
                                orderItem={item}
                                _fetchOrderItems={_fetchOrderItems}
                                packageName={packageName}
                                namespace={namespace}
                                orderType={orderType}
                                setArtWorkLevel={setArtWorkLevel}
                                setArtWorkPack={setArtWorkPack}
                                setArtworks={setArtworks}
                                listSuppliers={listSuppliers}
                                allArtworks={allArtworks}
                                validateDimension={validateDimension}
                            />
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

OrderItemTable.propTypes = {
    namespace: PropTypes.string,
    orderItems: PropTypes.array.isRequired,
    storeMode: PropTypes.string.isRequired,
    packageName: PropTypes.string
}

export default OrderItemTable