import React, { useEffect, useState } from 'react'
import { getListSuppliersV2 } from '../../../../services/api/SuppliersServices'
import SuppliersTable from './SuppliersTable'

const SupplierInfoModal = (props) => {
    const { visibleSupplier, supplier } = props

    const [suppliers, setSuppliers] = useState([])
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const _getSuppliers = async () => {
        setLoading(true)

        try {
            const params = { 
                page: 1,
                limit: 20,
                name: supplier
            }
            const { success, data, message } = await getListSuppliersV2(params)

            if (!success) throw new Error(message)

            setSuppliers(data.suppliers)
            setError('')
            setLoading(false)
        } catch (e) {
            setError(e.message)
            setLoading(false)
        }
    }

    const _renderSuppliersContent = () => {
        if (loading) return (
            <p>Loading...</p>
        )

        if (error) return (
            <p className='text-danger'>{error}</p>
        )

        if (suppliers.length === 0) return (
            <p className='text-danger'>No result</p>
        )

        return (
            <SuppliersTable suppliers={suppliers} />
        )
    }

    useEffect(() => {
        if(visibleSupplier){
            _getSuppliers()
        }
    }, [visibleSupplier])
    
    return (
        <div className='supplierModalContainer'>
           {_renderSuppliersContent()}
        </div>
    )
}

export default SupplierInfoModal
