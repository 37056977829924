import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Checkbox } from "antd"
import FilterOptions from "../../shared/FilterOptions"

class VariantsFilters extends Component {

    _handleChangeInput = (name, value) => {
        const { onChangeFilter } = this.props

        onChangeFilter({ [name]: value })
    }

    _handlChangeCheckbox = (e) => {
        const { onChangeFilter } = this.props
        const filterCheckbox = {
            stock_alert: e.target.checked
        }
        onChangeFilter(filterCheckbox)
    }

    render() {
        const { filters, onChangeFilter, optionSupplier } = this.props
        const { term, is_disabled, supplier, stock_alert, catalog_mapping_status, available_tiktok } = filters
        const Suppliers = optionSupplier.map(i => ({
            name: i.sku_prefix,
            value: i._id,
        }))

        const mappingStatus = [
            { name: 'Mapped', value: 'mapped' },
            { name: 'Unmapped', value: 'unmapped' },
        ]

        return (
            <div className='FiltersSuppliers d-flex flex-wrap align-items-center'>
                <div className="Name mr-sm-3 mb-3">
                    <div className="font-weight-500 Name mb-1">Product Title:</div>

                    <Input.Search value={term} placeholder='Enter to search...'
                        onChange={(e) => this._handleChangeInput('term', e.target.value)}
                        style={{ maxWidth: 300 }} allowClear />
                </div>

                <div className="Name mr-sm-3 mb-3">
                    <FilterOptions onChangeOption={onChangeFilter} selectedKey={is_disabled !== null ? is_disabled : ''}
                        field='is_disabled'
                        placeHolder="Select Status"
                        options={[
                            { name: 'Active', value: 'false' },
                            { name: 'Inactive', value: 'true' },
                        ]}
                        layout='select'
                        heading='Status Variant:'
                        defaultOption={''}
                        allowClear={true} />
                </div>

                <div className="Name mr-sm-3 mb-3">
                    <FilterOptions onChangeOption={onChangeFilter} selectedKey={supplier !== null ? supplier : ''}
                        field='supplier'
                        placeHolder="Select supplier"
                        options={Suppliers}
                        layout='select'
                        heading='Supplier:'
                        defaultOption={''}
                        allowClear={true}
                        showSearch={true}
                    />
                </div>

                <div className="Name mr-sm-3 mb-3">
                    <FilterOptions onChangeOption={onChangeFilter} selectedKey={catalog_mapping_status !== null ? catalog_mapping_status : ''}
                        field='catalog_mapping_status'
                        placeHolder="Mapping status"
                        options={mappingStatus}
                        layout='select'
                        heading='Mapping status:'
                        defaultOption={''}
                        allowClear={true} />
                </div>

                <div className="Name mr-sm-3 mb-3">
                    <FilterOptions onChangeOption={onChangeFilter} selectedKey={available_tiktok !== null ? available_tiktok : ''}
                        field='available_tiktok'
                        placeHolder="All"
                        options={[
                            { name: 'Yes', value: true },
                            { name: 'No', value: false },
                        ]}
                        layout='select'
                        heading='Available tiktok:'
                        defaultOption={''}
                    />
                </div>
                <div className="Name mr-sm-3 mb-3">
                    <div className="font-weight-500 Name mb-1">Stock Alert:</div>
                    <Checkbox checked={stock_alert} onChange={this._handlChangeCheckbox}>Yes</Checkbox>
                </div>
            </div>
        )
    }
}

VariantsFilters.propTypes = {
    onChangeFilter: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired
}

export default VariantsFilters
