import React, { useState, useEffect } from 'react'
import { Input, message, Modal, Tooltip } from 'antd'
import { QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { updateLabelTracking } from '../../../services/api/OrdersServices'

const { TextArea } = Input
const ModalEditLabel = (props) => {
    const { open, onClose, label = '', trackingNumber = '', orderId, reloadOrder } = props
    const [form, setForm] = useState({})

    useEffect(() => {
        !!label && setForm(preForm => ({ ...preForm, label_tiktok: label, tracking_number: trackingNumber }))
    }, [open])

    const handleOk = async () => {
        try {
            const payload = {}

            if (Object.keys(form).length > 0 && form.tracking_number) payload.tracking_number = form.tracking_number
            if (Object.keys(form).length > 0 && form.label_tiktok) payload.label_tiktok = form.label_tiktok
            if (form.label_tiktok && !form.label_tiktok.endsWith('.pdf')) return message.error("The link label must be a PDF link.")

            const {success, message: mess} = await updateLabelTracking(orderId, payload)
            if(!success) return message.error(mess)
            
            message.success("Update label and tracking number successfully.")
            handleCancel()
            reloadOrder()
        } catch (error) {
            message.error(error.message)
        }
    }

    const handleCancel = () => {
        onClose()
        setForm({})
    }

    const handleChangeForm = (value, field) => {
        setForm(preForm => ({ ...preForm, [field]: value.replace(/\s+/g, '') }))
    }

    return (
        <Modal
            title="Edit label"
            visible={open}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <div className="ModalEditLabel">
                <div className="input-item mb-3">
                    <label className='mb-1'>Link label</label>
                    <TextArea
                        value={form.label_tiktok}
                        onChange={(e) => handleChangeForm(e.target.value, 'label_tiktok')}
                        rows={3}
                    />
                </div>
                <div className="input-item">
                    <Tooltip
                        placement='topLeft'
                        title="Need to enter correct tracking number with label."
                    >
                        <div className='mb-1 d-inline-flex align-items-center cursor-pointer'>
                            Tracking number
                            <QuestionCircleOutlined
                                style={{
                                    fontSize: '14px',
                                    color: 'red',
                                    marginLeft: '8px',
                                }}
                            />
                        </div>
                    </Tooltip>
                    <Input
                        value={form.tracking_number}
                        onChange={(e) => handleChangeForm(e.target.value, 'tracking_number')}
                    />
                </div>
                <div className="d-inline-flex mt-2">
                    <WarningOutlined
                        style={{
                            fontSize: '14px',
                            color: 'orange',
                            marginRight: '8px',
                            marginTop: '5px'
                        }}
                    />
                    <i className='font-weight-bold'>The current shipping carrier information cannot be changed and will remain as the value of the "preference.shipping_carrier" field of this order.</i>
                </div>
            </div>
        </Modal>
    )
}

export default ModalEditLabel