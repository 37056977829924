import React, { Component } from 'react'
import FulfilledPackageItemProduct from "./FulfilledPackageItemProduct"
import FulfilledPackageItemMap from "./FulfilledPackageItemMap"
import FulfilledPackageItemDesignV3 from "./FulfilledPackageItemDesignV3"
import PropTypes from 'prop-types'

class FulfilledPackageItem extends Component {
    state = {
        artworkType: 'productvariant'
    }

    _handleChangeArtworkType = (type) => {
        this.setState({
            artworkType: type
        })
    }

    render() {
        const { artworkType } = this.state
        const {
            item,
            orderId,
            fulfilledPackageId,
            fulfillmentStatus,
            ff_processed_artworks_pf,
            supplierCurrent,
            supplierName,
            orderType,
            setArtworks,
            fetchOrderFulfilledItems,
            supplier,
            fetchHistory,
            slug,
            validateDimension
        } = this.props
        const {
            product,
            variant,
            price,
            quantity,
            productLineVariant,
            _id: orderItemId,
            product_label,
            currency,
            fulfillment_item_id,
            fulfillmentItems = []
        } = item

        const isCheckVersionOrderV2 = fulfillmentItems.length > 0 ? fulfillmentItems.some(item => item.artworks.length > 0) : false

        return (
            (fulfillmentItems.length > 1 && isCheckVersionOrderV2) ?
                fulfillmentItems.map((fulfillItem, index) => {
                    const { quantity: quantityItem, variant_data = {} } = fulfillItem
                    return (
                        <React.Fragment key={index}>
                            <tr className="FulfilledPackageItem">
                                {index === 0 && <FulfilledPackageItemProduct
                                    fulfilledPackageId={fulfilledPackageId}
                                    currency={currency}
                                    product={product}
                                    variant={variant}
                                    price={price}
                                    orderItem={item}
                                    quantity={quantity}
                                    productLabel={product_label}
                                    rowSpan={fulfillmentItems.length}
                                />}

                                <FulfilledPackageItemMap
                                    productLineVariant={productLineVariant}
                                    orderItemId={orderItemId}
                                    orderItem={item}
                                    artworkType={artworkType}
                                    orderType={orderType}
                                    quantity={quantityItem}
                                    isCheckVersionOrderV2={isCheckVersionOrderV2}
                                    fulfillItem={fulfillItem}
                                    supplier={supplier}
                                    orderId={orderId}
                                    fulfilledPackageId={fulfilledPackageId}
                                    fetchHistory={this.props.fetchHistory}
                                    slug={slug}
                                />

                                <FulfilledPackageItemDesignV3
                                    version={2}
                                    orderId={orderId}
                                    fulfilledPackageId={fulfilledPackageId}
                                    productMapId={variant_data.product_id}
                                    productLineVariant={variant_data}
                                    product={product}
                                    orderItemId={orderItemId}
                                    fulfillmentStatus={fulfillmentStatus}
                                    ff_processed_artworks_pf={ff_processed_artworks_pf}
                                    fulfillment_item_id={fulfillItem._id}
                                    onChangeArtworkType={this._handleChangeArtworkType}
                                    supplierCurrent={supplierCurrent}
                                    supplierName={supplierName}
                                    setArtworks={setArtworks}
                                    fulfillmentItems={fulfillmentItems}
                                    fulfillItem={fulfillItem}
                                    isCheckVersionOrderV2={isCheckVersionOrderV2}
                                    fetchOrderFulfilledItems={fetchOrderFulfilledItems}
                                    selectedComplexEmbroidery={this.props.selectedComplexEmbroidery}
                                    selectComplexEmbroidery={this.props.selectComplexEmbroidery}
                                    showEmbroiderySurchargeButton={this.props.showEmbroiderySurchargeButton}
                                    isAllowEmbroiderySurcharge={this.props.isAllowEmbroiderySurcharge}
                                    validateDimension={validateDimension}
                                />
                            </tr>
                        </React.Fragment>
                    )
                }) :
                <tr className="FulfilledPackageItem">
                    <FulfilledPackageItemProduct
                        fulfilledPackageId={fulfilledPackageId}
                        currency={currency}
                        product={product}
                        variant={variant}
                        price={price}
                        orderItem={item}
                        quantity={quantity}
                        productLabel={product_label}
                    />

                    <FulfilledPackageItemMap
                        productLineVariant={productLineVariant}
                        orderItemId={orderItemId}
                        orderItem={item}
                        artworkType={artworkType}
                        orderType={orderType}
                        quantity={fulfillmentItems[0] ? fulfillmentItems[0].quantity : ''}
                        isCheckVersionOrderV2={isCheckVersionOrderV2}
                        fulfillItem={fulfillmentItems[0]}
                        supplier={supplier}
                        orderId={orderId}
                        fulfilledPackageId={fulfilledPackageId}
                        fetchHistory={this.props.fetchHistory}
                        slug={slug}
                    />

                    <FulfilledPackageItemDesignV3
                        version={1}
                        orderId={orderId}
                        fulfilledPackageId={fulfilledPackageId}
                        productMapId={productLineVariant.product_id}
                        productLineVariant={productLineVariant}
                        product={product}
                        orderItemId={orderItemId}
                        fulfillmentStatus={fulfillmentStatus}
                        ff_processed_artworks_pf={ff_processed_artworks_pf}
                        fulfillment_item_id={!!fulfillmentItems[0] ? fulfillmentItems[0]._id : ''}
                        onChangeArtworkType={this._handleChangeArtworkType}
                        supplierCurrent={supplierCurrent}
                        supplierName={supplierName}
                        setArtworks={setArtworks}
                        fulfillmentItems={fulfillmentItems}
                        fulfillItem={fulfillmentItems[0]}
                        isCheckVersionOrderV2={isCheckVersionOrderV2}
                        fetchOrderFulfilledItems={fetchOrderFulfilledItems}
                        selectedComplexEmbroidery={this.props.selectedComplexEmbroidery}
                        selectComplexEmbroidery={this.props.selectComplexEmbroidery}
                        showEmbroiderySurchargeButton={this.props.showEmbroiderySurchargeButton}
                        isAllowEmbroiderySurcharge={this.props.isAllowEmbroiderySurcharge}
                        originFulfillItem={isCheckVersionOrderV2 ? fulfillmentItems[0] : item}
                        validateDimension={validateDimension}
                    />
                </tr>
        )
    }
}

FulfilledPackageItem.propTypes = {
    item: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    fulfilledPackageId: PropTypes.string
}

export default FulfilledPackageItem
