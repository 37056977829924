import React, { useState, useEffect } from 'react'
import { message, Pagination, Spin, Button } from "antd"
import { ReactComponent as SearchIcon } from '../../../statics/assets/icons/search.svg'
import UseDebounce from '../helpers/UseDebounce'
import CostControlTable from './CostControlTable'
import CostControlFilter from './Filter/CostControlFilter'
import UploadFile from './Action/UploadFile'
import { getListSuppliersV2, getSuppliersSupportingCostReconciliation } from '../../../services/api/SuppliersServices'
import { getListCostControl } from '../../../services/api/SuppliersServices'
import { getLocalData } from '../../../services/StorageServices'

function CostControlPage() {
    const [listCostControl, setListCostControl] = useState('')
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [supplier, setSupplier] = useState('')
    const [listSupplier, setListSupplier] = useState([])
    const [approvalStatus, setApprovalStatus] = useState('')
    const [fileName, setFileName] = useState('')
    const [createdStart, setCreatedStart] = useState('')
    const [createdEnd, setCreatedEnd] = useState('')
    const [pages, setPages] = useState(1)
    const [limit, setLimit] = useState(20)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')
    const [showUpLoadFile, setShowUpLoadFile] = useState(false)


    const fetchCostControl = async () => {
        setLoading(true)
        setError('')
        const payload = {
            page,
            limit,
        }
        if (supplier) payload.suppliers = [supplier]
        if (approvalStatus) payload.approval_status = approvalStatus
        if (fileName) payload.file_name = fileName
        if (createdStart && createdEnd) payload.created_at = {
            from: createdStart,
            to: createdEnd,
        }

        try {
            const {data, success, message:mess} = await getListCostControl({ ...payload })
            if (success) {
                const { pages, total, invoice_files } = data
                setListCostControl(invoice_files)
                setPages(pages)
                setTotal(total)
            }else{
                message.error(mess)
            }

            setLoading(false)
        } catch (error) {
            setError(error.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }

    }

    const getListSupplier = async () => {
        try {
            const { data, success, message:mess } = await getSuppliersSupportingCostReconciliation()
            if (success) {
                setListSupplier(data.suppliers)
            } else {
                message.error(mess)
            }
        } catch (error) {

        }
    }

    const debounceSearch =  UseDebounce(fileName, 500)

    const handlePageChange = (page, pageSize) => {
        setLimit(pageSize)
        setPage(pageSize !== limit ? 1 : page)
    }

    const handleChangeSupplier = (value) => {
        setSupplier(value)
        setPage(1)
    }

    const handleChangeApprovalStatus = (value) => {
        setApprovalStatus(value)
        setPage(1)
    }

    const handleChangeFileName = (e) => {
        const value = e.target.value
        if(value.length>=3)
        {
            setFileName(value)
            setPage(1)
        }else{
            setFileName('')
            setPage(1) 
        }
    }

    const handleChangeCreated = (momentArray, dateStringArray) => {
        setCreatedStart(dateStringArray[0])
        setCreatedEnd(dateStringArray[1])
        setPage(1)
    }

    useEffect(() => {
        fetchCostControl()
        getListSupplier()
    }, [approvalStatus, supplier, debounceSearch, createdStart, createdEnd, limit, page])

    const toggleModal = () => {
        setShowUpLoadFile(!showUpLoadFile)
    }
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []

    return (
        <div className='CostControlPage'>
            <div className='PageHeading d-flex align-items-center justify-content-between border-bottom'>
                <h1 className="PageTitle mt-2 mb-0">Cost Control</h1>
                <div className="RightTop">
                    <button className='btn btn-primary' disabled={loading} onClick={toggleModal} style={{ background: '#477BF6' }}>Upload file</button>
                    <UploadFile
                        showUpLoadFile={showUpLoadFile}
                        setShowUpLoadFile={setShowUpLoadFile}
                        listSupplier={listSupplier}
                        toggleModal={toggleModal}
                        reload={fetchCostControl}
                    ></UploadFile>
                </div>
            </div>
            <div className="SectionInner mt-4">
                <div className="filter align-items-center mt-2">
                    <CostControlFilter
                        supplier={supplier}
                        listSupplier={listSupplier}
                        approvalStatus={approvalStatus}
                        fileName={fileName}
                        createdStart={createdStart}
                        createdEnd={createdEnd}
                        loading={loading}
                        handleChangeCreated={handleChangeCreated}
                        handleChangeSupplier={handleChangeSupplier}
                        handleChangeFileName={handleChangeFileName}
                        handleChangeApprovalStatus={handleChangeApprovalStatus}
                    />
                </div>
                {
                    <div>
                        <div className='Total mb-3 d-flex justify-content-end'>
                            {
                                total > 0 && <div>
                                    <span>{total}</span> items
                                </div>
                            }
                        </div>
                        <Spin spinning={loading} tip='Data Loading...'>
                            <CostControlTable
                                listCostControl={listCostControl}
                                loading={loading}
                                reload={fetchCostControl}
                                permissionsData={permissionsData}
                            />
                            <Pagination
                                pageSizeOptions={['10', '20', '50', '100']}
                                onChange={handlePageChange}
                                className='text-right'
                                pageSize={limit}
                                showSizeChanger
                                current={page}
                                total={total}
                            />
                        </Spin>
                    </div>
                }
            </div>
        </div>
    )
}

export default CostControlPage