import React, {useState} from "react"
import { Modal, Input, message, Button } from "antd"
import { exportListVariants, exportVariants } from "../../../services/api/ExportOrderServices"
const Success = message.success

const ExportVariants = (prop) =>{
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [errors, setErrors] = useState({})
    const [linkGG, setLinkGG] = useState("")
    const [sheetName, setSheetName] = useState("")
    const [loading, setLoading] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleExport = async() => {
        const {filters} = prop
        const {term, is_disabled, supplier, stock_alert, catalog_mapping_status, available_tiktok} = filters
        setLoading(true)
        let err = {}
        const payload={linkGG,sheetName, query:{}}
        if(is_disabled!==''){
            payload.query.variant_status = is_disabled==='true'? 'false': is_disabled==='false'? 'true':'' 
        }
        if(supplier!=='') payload.query.supplier= supplier
        if(term!=='') payload.query.product_title= term
        if(stock_alert) payload.query.stock_alert= '1'
        if(catalog_mapping_status) payload.query.catalog_mapping_status = catalog_mapping_status
        if(available_tiktok !== '') payload.query.available_tiktok = available_tiktok

        const response = await exportVariants(payload)
        const { success, message } = response
        if(response){setLoading(false)}
        if(success==false){
            err.message= message
        }
        else{
            setIsModalVisible(false)
            setLinkGG("")
            setSheetName("")
            Success('Export successfully!!!')
        }
        setErrors(err)
    }

    const handleCancel = () => {
        setLinkGG("")
        setSheetName("")
        setErrors({})
        setIsModalVisible(false)
    }


    return(
        <>
        <Button type="primary" onClick={showModal}>Export</Button>
        <Modal 
            title={"Export List Variants"}
            visible={isModalVisible}
            okText={'Export'}
            onOk={handleExport}
            confirmLoading={loading}
            onCancel={handleCancel}
        >
            {errors && <small style={{color: "#ed4122", display:"block"}}>{errors.message}</small>}
            <div>
                <label>Link</label>
                <Input value={linkGG} onChange={e=>{setLinkGG(e.target.value)}} allowClear/>
                {errors && <small style={{color: "#ed4122", display:"block"}}>{errors.linkGG}</small>}
                <label>Name</label>
                <Input value={sheetName} onChange={e=>{setSheetName(e.target.value)}} allowClear/>
                {errors && <small style={{color: "#ed4122", display:"block"}}>{errors.sheetName}</small>}
                <p>
                    Share your Google Sheet to this account:
                </p>
                <b>exporter@platform126.iam.gserviceaccount.com</b>
            </div>
        </Modal>
        </>
    )
}

export default ExportVariants