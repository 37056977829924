import React from 'react'
import { Select, Input, DatePicker } from 'antd'
import moment from 'moment'
import { ReactComponent as SearchIcon } from '../../../../statics/assets/icons/search.svg'

function CostControlFilter(props) {
    const { RangePicker } = DatePicker
    const { Option } = Select
    const {
        supplier, approvalStatus,
        loading, fileName,
        handleChangeSupplier,
        handleChangeApprovalStatus,
        handleChangeFileName,
        handleChangeCreated,
        createdStart, createdEnd,
        listSupplier = [],
    } = props
    const dateFormat = "DD/MM/YYYY"
    const dateFilterValue = createdStart && createdEnd ? [moment(createdStart, dateFormat), moment(createdEnd, dateFormat)] : []
    const supplierOption = !!listSupplier && listSupplier.length > 0 && listSupplier.map(item =>
        <Option key={item._id} value={item._id}>{item.name}</Option>
    )

    return (
        <div className='CostControlFilter TableFilter d-xl-flex justify-content-between align-items-end'>
            <div className="Filter">
                <div className="d-flex flex-wrap justify-content-start">
                    <div className="FilterWrapper FilterOptions mb-3 mr-3">
                        <div className="d-flex align-items-stretch justify-content-end">
                            <Input
                                style={{ minWidth: "400px" }}
                                placeholder='Search for file or package name'
                                // value={fileName || ''}
                                onChange={handleChangeFileName}
                                allowClear
                                disabled={loading}
                                className='searchFileName'
                                prefix={<SearchIcon className='mr-2' />}
                            />
                        </div>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <Select
                            style={{ minWidth: "200px" }}
                            placeholder="-- Supplier --"
                            allowClear
                            value={supplier || []}
                            onChange={handleChangeSupplier}
                            disabled={loading}
                            filterOption={(input, option) =>
                                option.children.toLocaleLowerCase().includes((input || "").toLocaleLowerCase())
                            }
                            showSearch
                        >
                            {supplierOption}
                        </Select>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <Select
                            style={{ minWidth: "200px" }}
                            placeholder="-- Approval status --"
                            value={approvalStatus || []}
                            onChange={handleChangeApprovalStatus}
                            allowClear
                            disabled={loading}
                        >
                            <Option value="pending">Pending</Option>
                            <Option value="approved">Approved</Option>
                            <Option value="rejected">Rejected</Option>
                        </Select>
                    </div>

                    <div className='CustomSelect FilterOptions mr-3 mb-3'>
                        <RangePicker
                            id='createdAt'
                            placeholder={['Created start', 'Created end']}
                            ranges={{
                                'Today': [moment().startOf('day'), moment().endOf('day')],
                                'Last 7 Days': [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')],
                                'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                            }}
                            format={dateFormat}
                            onChange={handleChangeCreated}
                            popupStyle={{ maxWidth: '600px' }}
                            disabled={loading}
                            value={dateFilterValue}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CostControlFilter