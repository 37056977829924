import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SupplierInfoModal from '../../../../order-partners-v2/components/data/SupplierInfoModal'

class ProductsSupplierItem extends Component {
    state = {
        visibleSupplier: false,
    }

    _handleChangeSupplier = (value) => {
        const {onChangeSupplier} = this.props
        if (typeof onChangeSupplier === 'function') {
            onChangeSupplier(value)
        }
    }

    _handleSetVisibleSupplier = (value) => {
        this.setState({
            visibleSupplier: value
        })
    }

    render() {
        const {supplier, supplierId} = this.props 
        
        return (
            <li className="cursor-pointer"
                onMouseOver={() => this._handleSetVisibleSupplier(true)}
                onMouseLeave={() => this._handleSetVisibleSupplier(false)}
                >
                    <span onClick={() => this._handleChangeSupplier([supplierId])}>
                    {supplier}
                    </span>

                    {
                        this.state.visibleSupplier && <SupplierInfoModal visibleSupplier={this.state.visibleSupplier} supplier={supplier}/>
                    }
                </li>
        )
    }
}

ProductsSupplierItem.propTypes = {
    supplier: PropTypes.string.isRequired,
}

export default ProductsSupplierItem