import React, {Component} from 'react'
import FulfilledPackageItem from "./FulfilledPackageItem"
import PropTypes from 'prop-types'

class FulfilledPackageItems extends Component {

    render() {
        const {
            items, orderId, fulfilledPackageId, fulfillmentStatus, ff_processed_artworks_pf, supplierCurrent, supplierName, 
            orderType, setArtworks, fetchOrderFulfilledItems, supplier, fetchHistory, slug, validateDimension
        } = this.props

        return (
            <div className="FulfilledPackageItems bg-white">
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th>Product</th>
                        <th className="table-col-40">Map</th>
                        <th className="table-col-20">Design</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        items.map(item => {
                            return <FulfilledPackageItem
                                orderId={orderId}
                                key={item._id}
                                item={item}
                                fulfilledPackageId={fulfilledPackageId}
                                fulfillmentStatus={fulfillmentStatus}
                                ff_processed_artworks_pf={ff_processed_artworks_pf}
                                supplierCurrent={supplierCurrent}
                                supplierName={supplierName}
                                orderType={orderType}
                                setArtworks={setArtworks}
                                fetchOrderFulfilledItems={fetchOrderFulfilledItems}
                                selectedComplexEmbroidery={this.props.selectedComplexEmbroidery}
                                selectComplexEmbroidery={this.props.selectComplexEmbroidery}
                                showEmbroiderySurchargeButton={this.props.showEmbroiderySurchargeButton}
                                hasEmbroiderySurcharge={this.props.hasEmbroiderySurcharge}
                                isAllowEmbroiderySurcharge={this.props.isAllowEmbroiderySurcharge}
                                supplier={supplier}
                                fetchHistory={this.props.fetchHistory}
                                slug={slug}
                                validateDimension={validateDimension}
                            />
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

FulfilledPackageItems.propTypes = {
    orderId: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    fulfilledPackageId: PropTypes.string
}

export default FulfilledPackageItems
