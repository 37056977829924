import React, { useContext, useEffect, useState } from 'react'
import InputSearch from "./filters/InputSearch"
import SelectSearch from "./filters/SelectSearch"
import { Input, Select, Checkbox } from 'antd'
import {
    PACKAGE_STATUS, DELAY_STATUS, PRODUCTION_STATUS_SUPPLIER,
    PUSH_TYPE, TRACKING_STATUS, DELAY_DAYS, DID_ACTIONS, LIST_SUB_EXCEPTION,
    SHIPPING_LEVELS, ORIGINAL_CARRIERS,
    ORDER_TYPE, STORE_MODE, SHIPPING_ZONE, UPDATED_TRACKING
} from "../helpers/orderFilters"
import OrderPartnersContext from './../context/OrderPartnersContext'
import { getListSuppliersV2 } from '../../../services/api/SuppliersServices'
import { getListSuggestNotes } from '../../../services/api/OrdersServices'
import FilterOptions from "../../shared/FilterOptions"
import FilterDate from "../../shared/FilterDate"
import CustomMultiSelect from './filters/CustomMultiSelect'
import CustomInputMultiSelect from './filters/CustomInputMultiSelect'
import CustomSelectMultiLevel from "../../shared/CustomSelectMultiLevel"
import { getListCarriers } from '../../../services/api/ShippingServices'

const OrderPartnersFilters = (props) => {
    const { loading } = props
    const { filters, setState, allowedUpdateTags = {} } = useContext(OrderPartnersContext)
    const _packageStatusFilter = filters.package_status || []
    const packageStatusFilter = typeof _packageStatusFilter === 'string' ? [_packageStatusFilter] : _packageStatusFilter
    const _delayedDays = filters.delayed_days || []
    const delayedDaysFilter = typeof _delayedDays === 'string' ? [_delayedDays] : _delayedDays
    const [suppliers, _setSuppliers] = useState([])
    const [listSuggestNotes, setListSuggestNotes] = useState([])
    const [suggestNotes, setSuggestNotes] = useState([])
    const [isShow, setIsShow] = useState(false)
    const [isSuggest, setIsSuggest] = useState(false)
    const [shippingCarriers, setShippingCarriers] = useState([])
    const [originalCarriers, setOriginalCarriers] = useState([])

    const _fetchListSuppliers = async () => {
        try {
            const { data, success, message } = await getListSuppliersV2({ active: true })

            if (!success) {
                console.log(message)
                return
            }

            const _listSuppliers = data.suppliers.map(option => ({ name: option.name, value: option._id }))

            _setSuppliers(_listSuppliers)
        } catch (e) {
            console.log(e)
        }
    }

    const _fetchListSuggestNotes = async () => {

        try {
            const { data, success, message } = await getListSuggestNotes()

            if (!success) {
                console.log(message)
                return
            }
            setListSuggestNotes(data)

        } catch (error) {
            console.log(error)
        }
    }

    const _handleChangeSuppliers = (paramFilter) => {
        const _filters = {
            ...filters,
            ...paramFilter
        }
        setState(_filters)
    }

    const _handleChangeInput = (name, value) => {
        const _filters = {
            ...filters,
            [name]: value
        }
        setState(_filters)
    }

    const _handleChangeInputNumber = (e) => {
        const { name, value } = e.target
        _handleChangeSelect(name, value)
    }

    const _handleChangeInputSearch = (e) => {
        const { name, value } = e.target
        let matches = []
        if (value.length > 0) {
            matches = listSuggestNotes.filter(note => {
                const regex = new RegExp(`${value}`, "gi")
                return note.match(regex)
            })
        }
        (!listSuggestNotes.includes(value) && value.length > 0) ? setIsShow(false) : setIsShow(true)
        setIsSuggest(true)
        setSuggestNotes(matches)
        _handleChangeSelect(name, value)
    }

    const onSuggetsHandler = (value) => {
        _handleChangeSelect('order_status_note', value)
        setSuggestNotes([])
    }

    const _handleChangeSelect = (name, value) => {
        const _filters = {
            ...filters,
            [name]: value,
        }

        if ((name === 'did_actions' && (value.includes('shipbytiktok') || value.includes('tiktokshipbyseller')))) _filters.order_type = 'TIKTOK'

        if (_filters.delay !== 'in_transit_delay' && _filters.delay !== 'ship_delay_from_completed_at' && _filters.delay !== 'production_delay_from_pushed_at' && _filters.delay !== 'multrans_us_pre_transit_delay') {
            delete _filters.delayed_days
        }

        if (name === "did_actions") delete _filters.nin_did_actions

        setState(_filters)
    }

    const _handlChangeCheckbox = (e) => {
        const { checked, name } = e.target

        const _filters = {
            ...filters,
            [name]: checked ? '1' : '0'
        }

        if (_filters.delay !== 'in_transit_delay' && _filters.delay !== 'ship_delay_from_completed_at' && _filters.delay !== 'production_delay_from_pushed_at' && _filters.delay !== 'multrans_us_pre_transit_delay') {
            delete _filters.delayed_days
        }

        setState(_filters)
    }

    const ClearShowNote = () => {
        setTimeout(() => {
            setIsSuggest(false)
            setIsShow(false)
        }, 200)
    }

    const ShowNote = (e) => {
        const { value } = e.target
        // !listSuggestNotes.some(i=>i.toLowerCase().includes(value)||i.includes(value)) && value.length > 0 && value !== '' ? setIsShow(false) : setIsShow(true)
        if (listSuggestNotes.some(i => i.toLowerCase().includes(value) || i.includes(value)) && value.length > 0 && value !== '') {
            setIsSuggest(true)
            setIsShow(false)
        } else if (value.length === 0 && value === '') {
            setIsShow(true)
            setIsSuggest(false)
        } else {
            setIsShow(false)
            setIsSuggest(false)
        }
    }

    const getListDelay = () => {
        const items = [...DELAY_STATUS]

        if (filters.delay) {
            const m = filters.delay.match(/ship_delay_gt_days_([0-9]+)/)

            if (m && !['13', '16', '21'].includes(`${m[1]}`)) {
                const d = m[1] > 1 ? 'DBs' : 'DB'
                items.push({
                    name: `Custom delay ship > ${m[1]}${d}`,
                    value: `ship_delay_gt_days_${m[1]}`,
                },)
            }
        }

        return items
    }

    const _fetchCarrier = async () => {
        try {
            const { data, success, message } = await getListCarriers()
            if (!success) {
                console.log(message)
                return
            }
            setShippingCarriers(data.shippingCarriers)
            setOriginalCarriers(data.trackingCompanies)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        _fetchListSuppliers()
        _fetchListSuggestNotes()
        _fetchCarrier()
    }, [])

    const shippingCarrierOptions = [{ name: 'All', value: '', }, ...shippingCarriers.map(i => ({ name: i, value: i }))]
    const originalCarrierOptions = [{ name: 'All', value: '', }, ...originalCarriers.map(i => ({ name: i, value: i }))]
    const showDelayedDays = filters.delay === 'in_transit_delay' || filters.delay === 'ship_delay_from_completed_at' || filters.delay === 'production_delay_from_pushed_at'
    const tagOptions = Object.keys(allowedUpdateTags).length ? Object.keys(allowedUpdateTags).map(key => ({
        value: key,
        name: allowedUpdateTags[key],
        focus: key,
    })) : []

    return suppliers.length > 0 && (
        <div className="NotificationFilters OrdersSuplier">
            <div className="d-flex GroupFilter">
                <div className="itemFilter mb-3">
                    <div className="font-weight-500 LabelFilter mb-1">Suppliers:</div>
                    <FilterOptions
                        selectedKey={filters.suppliers || []}
                        field='suppliers'
                        options={suppliers}
                        defaultOption={[]}
                        layout='select'
                        mode='multiple'
                        onChangeOption={_handleChangeSuppliers}
                        loading={loading}
                        showSearch={true}
                    />
                </div>
                <div className="itemFilter mb-3">
                    <InputSearch
                        name="custom_references"
                        label="Custom Reference:"
                        value={filters.custom_references || []}
                        onChange={_handleChangeInput}
                        loading={loading}
                    />
                </div>
                <div className="itemFilter mb-3">
                    <InputSearch
                        name="namespaces"
                        onChange={_handleChangeInput}
                        label="Namespace:"
                        value={filters.namespaces || []}
                        loading={loading}
                    />
                </div>
                <div className="itemFilter mb-3">
                    <InputSearch
                        name="names"
                        onChange={_handleChangeInput}
                        label="Package name:"
                        value={filters.names || []}
                        loading={loading}
                    />
                </div>
                <div className="itemFilter mb-3">
                    <CustomMultiSelect
                        name='did_actions'
                        label={'Should noticed:'}
                        placeholder={'All'}
                        selected={filters.did_actions || ''}
                        listFilters={[...DID_ACTIONS, ...tagOptions]}
                        onChangeFilters={_handleChangeSelect}
                        loading={loading}
                    />
                </div>
                <div className="itemFilter filterPushedAt mb-3">
                    <FilterDate
                        heading='Pushed at:'
                        field='pushed_at'
                        value={filters.pushed_at || {}}
                        onDatesChange={_handleChangeSuppliers}
                        loading={loading}
                        showTime={true}
                        hidenSuffix={true}
                    />
                </div>
            </div>
            <div className="d-flex GroupFilter">
                <div className="itemFilter status mb-3">
                    <SelectSearch
                        name="shipping_carrier"
                        value={filters.shipping_carrier || ''}
                        onChange={_handleChangeSelect}
                        label="Shipping carrier:"
                        listSelect={shippingCarrierOptions}
                        loading={loading}
                        showSearch={true}
                    />
                </div>
                <div className="itemFilter status mb-3">
                    <SelectSearch
                        name="tracking_company"
                        value={filters.tracking_company || ''}
                        onChange={_handleChangeSelect}
                        label="Original carrier:"
                        listSelect={originalCarrierOptions}
                        loading={loading}
                        showSearch={true}
                    />
                </div>
                <div className="itemFilter status mb-3">
                    <CustomSelectMultiLevel
                        name='package_status'
                        label={'Package status:'}
                        placeholder={'All'}
                        selected={packageStatusFilter}
                        listFilters={PACKAGE_STATUS}
                        listSub={LIST_SUB_EXCEPTION}
                        onChangeFilters={_handleChangeSelect}
                        loading={loading}
                    />
                </div>
                <div className="itemFilter status mb-3">
                    <SelectSearch
                        name="production_status"
                        value={filters.production_status || ''}
                        onChange={_handleChangeSelect} label="Production status:"
                        listSelect={PRODUCTION_STATUS_SUPPLIER}
                        loading={loading}
                        showSearch={true}
                    />
                </div>
                <div className="itemFilter status mb-3">
                    <CustomMultiSelect
                        name='tracking_status'
                        label={'Tracking status:'}
                        placeholder={'All'}
                        selected={filters.tracking_status || ''}
                        listFilters={TRACKING_STATUS}
                        onChangeFilters={_handleChangeSelect}
                        loading={loading}
                    />
                </div>
                <div className="itemFilter filterPushedAt mb-3">
                    <FilterDate
                        heading='Package at:'
                        field='packaged_at'
                        value={filters.packaged_at || {}}
                        onDatesChange={_handleChangeSuppliers}
                        loading={loading}
                    />
                </div>
            </div>
            <div className="d-flex GroupFilter">
                <div className="itemFilter end shippingLevel mb-3">
                    <SelectSearch
                        name="shipping_level"
                        value={filters.shipping_level || ''}
                        onChange={_handleChangeSelect}
                        label="Shipping level:"
                        listSelect={SHIPPING_LEVELS}
                        loading={loading}
                    />
                </div>

                <div className="itemFilter end pushType mb-3">
                    <SelectSearch
                        name="pushed_type"
                        value={filters.pushed_type || ''}
                        onChange={_handleChangeSelect}
                        label="Push type:"
                        listSelect={PUSH_TYPE}
                        loading={loading}
                    />
                </div>
                <div className="itemFilter end status mr-3 mb-3">
                    <SelectSearch
                        name="delay"
                        value={filters.delay || ''}
                        onChange={_handleChangeSelect}
                        label="Delay:"
                        listSelect={getListDelay()}
                        loading={loading}
                        showSearch={true}
                    />
                </div>
                {showDelayedDays && (
                    <div className="itemFilter end status mb-3">
                        <CustomInputMultiSelect
                            name='delayed_days'
                            label={'Delayed days:'}
                            placeholder={'Enter number to search'}
                            selected={delayedDaysFilter}
                            listFilters={DELAY_DAYS}
                            onChangeFilters={_handleChangeSelect}
                            loading={loading}
                        />
                    </div>
                )}
                <div className="itemFilter mb-3">
                    <div className="InputSearch">
                        <div className="font-weight-500 LabelFilter mb-1">Update tracking delay days:</div>
                        <Input
                            name='tracking_update_delayed_days'
                            value={filters.tracking_update_delayed_days}
                            onChange={_handleChangeInputNumber}
                            placeholder="Enter number to search"
                            maxLength={25}
                            autoComplete="off"
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="itemFilter mb-3">
                    <InputSearch
                        name="tracking_last_notes"
                        onChange={_handleChangeInput}
                        regex=","
                        label="Tracking last notes:"
                        value={filters.tracking_last_notes || []}
                        loading={loading}
                    />
                </div>
                {!showDelayedDays &&
                    <div className="itemFilter mb-3">
                        <div className="font-weight-500 LabelFilter mb-1">Order status note:</div>
                        <Input
                            name="order_status_note"
                            onChange={_handleChangeInputSearch}
                            value={filters.order_status_note}
                            placeholder="Enter to search"
                            allowClear
                            onBlur={ClearShowNote}
                            onFocus={ShowNote}
                            autoComplete="off"
                            disabled={loading}
                        />
                        <div className="listSuggestNotes">
                            {
                                isSuggest && suggestNotes.length > 0 ? suggestNotes.map((item, index) => (
                                    <div className="suggestItem" key={index}
                                        onClick={() => onSuggetsHandler(item)}>{item}</div>
                                )) :
                                    isShow && listSuggestNotes.map((item, index) => (
                                        <div className="suggestItem" key={index}
                                            onClick={() => onSuggetsHandler(item)}>{item}</div>
                                    ))
                            }
                        </div>
                    </div>
                }
            </div>
            <div className="d-flex GroupFilter">
                {showDelayedDays &&
                    <div className="itemFilter mb-3">
                        <div className="font-weight-500 LabelFilter mb-1">Order status note:</div>
                        <Input
                            name="order_status_note"
                            onChange={_handleChangeInputSearch}
                            value={filters.order_status_note}
                            placeholder="Enter to search"
                            allowClear
                            onBlur={ClearShowNote}
                            onFocus={ShowNote}
                            autoComplete="off"
                            disabled={loading}
                        />
                        <div className="listSuggestNotes">
                            {
                                isSuggest && suggestNotes.length > 0 ? suggestNotes.map((item, index) => (
                                    <div className="suggestItem" key={index}
                                        onClick={() => onSuggetsHandler(item)}>{item}</div>
                                )) :
                                    isShow && listSuggestNotes.map((item, index) => (
                                        <div className="suggestItem" key={index}
                                            onClick={() => onSuggetsHandler(item)}>{item}</div>
                                    ))
                            }
                        </div>
                    </div>
                }
                <div className="itemFilter orderType mb-3">
                    <SelectSearch
                        name="order_type"
                        value={filters.order_type || ''}
                        onChange={_handleChangeSelect}
                        label="Order type:"
                        listSelect={ORDER_TYPE}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    )
}

export default OrderPartnersFilters
